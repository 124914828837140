import React from "react"
import PropTypes from "prop-types"

import Icon from "./icon"
import Inline from "./inline"
import Text from "./text"

const FormMessage = ({ children, tone }) => {
  return (
    <Inline alignY="center" space={2}>
      <Icon icon={`form-${tone}`} color={tone} size={4} />
      <Text sans size={1} color={tone}>
        {children}
      </Text>
    </Inline>
  )
}

FormMessage.propTypes = {
  /**
   * text color and icon are based on the tone of the message
   */
  tone: PropTypes.oneOf(["positive", "neutral", "negative"]),
}

FormMessage.defaultProps = {
  tone: "neutral",
}

export default FormMessage
