/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Box from "./box"
import Label from "./label"
import Stack from "./stack"
import FormMessage from "./form-message"

const Input = React.forwardRef((props, ref) => {
  const {
    align,
    bold,
    id,
    label,
    message,
    overlay,
    readOnly,
    tone,
    type,
    ...restProps
  } = props

  return (
    <Stack space={2}>
      {label && (
        <Label htmlFor={id} bold={bold}>
          {label}
        </Label>
      )}
      <Box position="relative">
        <Box
          as="input"
          type={type}
          id={id}
          ref={ref}
          readOnly={readOnly}
          {...restProps}
          sx={{
            appearance: "none",
            display: "block",
            width: "100%",
            fontSize: "inherit",
            fontFamily: "sans",
            lineHeight: "inherit",
            border: "1px solid",
            borderColor: tone === "negative" ? "negative" : "border",
            borderRadius: "md",
            textAlign: align,
            color: readOnly ? "whisper" : "inherit",
            bg: readOnly ? "rgba(0,0,0,0.05)" : "transparent",
            cursor: readOnly ? "text" : null,
            p: 2,
            pr: overlay ? 10 : 2,
            variant: "forms.input",
            "&[type=number]": {
              MozAppearance: "textfield",
            },
            "&[type=number]::-webkit-outer-spin-button,&[type=number]::-webkit-inner-spin-button,":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
          }}
        />
        {overlay && (
          <Box
            position="absolute"
            right={0}
            top={0}
            bottom={0}
            display="flex"
            alignY="center"
          >
            {overlay}
          </Box>
        )}
      </Box>
      {message && <FormMessage tone={tone}>{message}</FormMessage>}
    </Stack>
  )
})

Input.propTypes = {
  /**
   * unique id for the input. when a label is provided this will automatically
   * be used as the `for` attribute
   */
  id: PropTypes.string.isRequired,
  /**
   * Label for the input. Make sure to skip this only when you are providing
   * an alternative label (and have a good reason to break our design
   * conventions around labels on top of inputs)
   */
  label: PropTypes.node,
  /**
   * only a subset of input attributes are supported due to limited styling support
   */
  type: PropTypes.oneOf([
    "date",
    "datetime-local",
    "email",
    "month",
    "number",
    "password",
    "search",
    "tel",
    "text",
    "time",
    "url",
    "week",
  ]),
  /**
   * Text-Alignment for the input
   */
  align: PropTypes.oneOf(["left", "center", "right"]),
  /**
   * applies disabled styles and `readOnly` (watch the casing!) to the input
   */
  readOnly: PropTypes.bool,
  /**
   * when an overlay provided it'll be positioned to the right of the input
   * and additional padding applied. use this for actions like clear or show
   * password
   */
  overlay: PropTypes.node,
  /**
   * this controls wether the label should be bold or not
   */
  bold: PropTypes.bool,
  /**
   * optional message which is displayed beneath the input. used for error
   * messages and instructions
   */
  message: PropTypes.string,
  /**
   * text color and icon are based on the tone of the message
   */
  tone: PropTypes.oneOf(["positive", "neutral", "negative"]),
}

Input.defaultProps = {
  bold: true,
  align: "left",
  type: "text",
}

export default Input
